<!--地图找房-->
<template>
    <div class="houseMap">
        <div class="params-box">
            <div class="clearfix">
                <label class="fl">地区</label>
                <i class="fl" style="margin-right: 20px">:</i>
                <div
                    class="fl pionter"
                    :class="{ isactive: !params.filter.regionCode }"
                    @click="paramsChange('regionCode',null)"
                >
                    不限
                </div>
                <ul class="clearfix fl search-list">
                    <li
                    v-for="item in region"
                    class="fl classify-item"
                    :key="item.code"
                    :class="{ isactive: params.filter.regionCode == item.code }"
                        @click="paramsChange('regionCode',item.code)"
                    >
                    {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="clearfix">
                <label class="fl">户型</label>
                <i class="fl" style="margin-right: 20px">:</i>
                <div
                class=" fl pionter"
                :class="{ isactive: !params.filter.houseType }"
                @click="paramsChange('houseType','')"
                >
                不限
                </div>
                <ul class="clearfix fl search-list">
                <li
                    v-for="item in 12"
                    class="fl classify-item"
                    :key="item+'size'"
                    :class="{ isactive: params.filter.houseType == item+'室' }"
                    @click="paramsChange('houseType',item+'室')"
                    
                >
                    {{ item }}室
                </li>
                </ul>
            </div>
            <div class="clearfix">
                <label class="fl">用途</label>
                <i class="fl" style="margin-right: 20px">:</i>
                <div
                class=" fl pionter"
                :class="{ isactive: !params.filter.rentUse }"
                @click="paramsChange('rentUse','')"
                >
                不限
                </div>
                <ul class="clearfix fl search-list">
                <li
                    v-for="item in rentUse"
                    class="fl classify-item"
                    :key="item+'rentUse'"
                    :class="{ isactive: params.filter.rentUse == item }"
                    @click="paramsChange('rentUse',item)"
                    
                >
                    {{ item }}
                </li>
                </ul>
            </div>
            <div class="clearfix">
                <label class="fl">价格</label>
                <i class="fl" style="margin-right: 20px">:</i>
                <div
                class=" fl pionter"
                :class="{ isactive: !params.filter.price }"
                @click="paramsChange('price','')"
                >
                不限
                </div>
                <ul class="clearfix fl search-list">
                <li
                    v-for="item in rental"
                    class="fl classify-item"
                    :key="item.value+'price'"
                    :class="{ isactive: params.filter.price == item.value }"
                    @click="paramsChange('price',item.value)"
                    
                >
                    {{ item.rent }}
                </li>
                </ul>
            </div>
        </div>
        <div class="btn">
            <el-button type="text" @click="clearParams">清空筛选项</el-button>
        </div>
        <div class="loadingbox" v-loading="loading">
            <div class="map" v-if="pageData.length>0&!loading" >
                <template>
                    <!-- <baidu-map class="map" :center="'西宁市'" :zoom="14" :scroll-wheel-zoom="true">
                        <bm-marker v-for="item in pageData" @click="infoWindowOpen(item)" :key="item.id" :icon="{url: require('../../assets/img/mapMarket.png'), size: {width: 28, height: 36}}" :position="{lng:item.longitude,lat:item.latitude}">
                            <bm-info-window :show="item.show" @close="infoWindowClose(item)" @open="infoWindowOpen(item)" >
                                <div class="win-box">
                                    <div class="photo">
                                        <el-image fit="cover" style="width: 100%; height: 100%" :src="item.photoUrls && item.photoUrls.split(',')[0]"></el-image>
                                    </div>
                                    <div class="info">
                                        <div>{{item.title}}</div>
                                        <div>{{item.buildArea}}m² {{item.houseType}} {{item.houseLayout}} {{item.decoration}}</div>
                                        <div>{{item.features}}</div>
                                        <div>联系电话：{{item.telephone}}</div>
                                    </div>
                                </div>
                            </bm-info-window>
                        </bm-marker>
                    </baidu-map> -->
                    <arc-map :infow="true" :list="pageData" :center="[101.779, 36.617]"></arc-map>
                </template>
            </div>
            <div class="empty" v-else>
                <el-empty description="暂无相关数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { GetRentInfos } from "@/api/ziyuanjishi";

export default {
    data(){
        return{
            region: [
                // { name: "全部", code: 0 },
                { name: "大通县", code: 630121 },
                { name: "湟源县", code: 630123 },
                { name: "湟中区", code: 630122 },
                { name: "城东区", code: 630102 },
                { name: "城中区", code: 630103 },
                { name: "城西区", code: 630104 },
                { name: "城北区", code: 630105 },
            ],
            params:{currentPage:1,pageSize:99999,filter:{}},
            rentUse:['民宿','农家乐','康养','工作室','仓储','其他'],
            rental: [
                { rent: "1千元以下", value: '0-1000' },
                { rent: "1~2千元", value: '1000-2000' },
                { rent: "2~3千元", value: '2000-3000' },
                { rent: "3千元以上", value: '3000-1000000000' },
            ],
            pageData:[],
            show:true,
            loading:false
        }
    },
    methods:{
        getPageList(){
            this.loading = true
            GetRentInfos(this.params).then(res=>{
                console.log(res.data.data.list)
                this.loading = false
                res.data.data.list.forEach(item=>{
                    item.show = false
                })
                this.pageData = res.data.data.list;
            }).catch(()=>{this.loading = false})
        },
        paramsChange(obj,value){
            if(value){
                this.$set(this.params.filter,obj,value)
            }else{
                delete this.params.filter[obj]
            }
            this.getPageList();
        },
        clearParams(){
            this.params.filter = {};
            this.getPageList();
        },
        infoWindowClose (item) {
            this.$set(item,'show',false) ;
        },
        infoWindowOpen (item) {
            this.$set(item,'show',true);
        },
        market(item){
            console.log(1);
        }
    },
    mounted(){
        this.getPageList();
    }
}
</script>

<style scoped>
.info{
    font-size: 14px;
    line-height: 20px;
    color: #777;
}
.win-box{
    width: 220px;
    padding-top: 20px;
}
.photo{
    width: 220px;
    height: 156px;
}
.map{
    height: 800px;
}
.btn >>> .el-button{
    font-size: 16px;
}
.classify-item {
    padding: 0px 20px;
    font-size: 16px;
    cursor: pointer;
    color: #333;
}
.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
  cursor: pointer;
}
.params-box {
  margin-top: 20px;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 16px;
  /* font-weight: 600; */
  /* height: 40px; */
  line-height: 40px;
}
.params-box label {
  width: 50px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 600;
}
</style>