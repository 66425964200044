<!--求租信息-->
<template>
    <div class="rent">
        <el-tabs v-model="activeName" type="card" style="margin-top:20px" @tab-click="tabChange">
            <el-tab-pane label="求租信息" name="first">
                <div class="params-box">
                    <div class="clearfix">
                        <label class="fl">地区</label>
                        <i class="fl" style="margin-right: 20px">:</i>
                        <div
                            class="fl pionter"
                            :class="{ isactive: !params.filter.regionCode }"
                            @click="paramsChange('regionCode',null)"
                        >
                            不限
                        </div>
                        <ul class="clearfix fl search-list">
                            <li
                            v-for="item in region"
                            class="fl classify-item"
                            :key="item.code"
                            :class="{ isactive: params.filter.regionCode == item.code }"
                                @click="paramsChange('regionCode',item.code)"
                            >
                            {{ item.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix">
                        <label class="fl">户型</label>
                        <i class="fl" style="margin-right: 20px">:</i>
                        <div
                        class=" fl pionter"
                        :class="{ isactive: !params.filter.houseType }"
                        @click="paramsChange('houseType','')"
                        >
                        不限
                        </div>
                        <ul class="clearfix fl search-list">
                        <li
                            v-for="item in 12"
                            class="fl classify-item"
                            :key="item+'size'"
                            :class="{ isactive: params.filter.houseType == item+'室' }"
                            @click="paramsChange('houseType',item+'室')"
                            
                        >
                            {{ item }}室
                        </li>
                        </ul>
                    </div>
                    <div class="clearfix">
                        <label class="fl">用途</label>
                        <i class="fl" style="margin-right: 20px">:</i>
                        <div
                        class=" fl pionter"
                        :class="{ isactive: !params.filter.rentUse }"
                        @click="paramsChange('rentUse','')"
                        >
                        不限
                        </div>
                        <ul class="clearfix fl search-list">
                        <li
                            v-for="item in rentUse"
                            class="fl classify-item"
                            :key="item+'rentUse'"
                            :class="{ isactive: params.filter.rentUse == item }"
                            @click="paramsChange('rentUse',item)"
                            
                        >
                            {{ item }}
                        </li>
                        </ul>
                    </div>
                    <div class="clearfix">
                        <label class="fl">价格</label>
                        <i class="fl" style="margin-right: 20px">:</i>
                        <div
                        class=" fl pionter"
                        :class="{ isactive: !params.filter.price }"
                        @click="paramsChange('price','')"
                        >
                        不限
                        </div>
                        <ul class="clearfix fl search-list">
                        <li
                            v-for="item in rental"
                            class="fl classify-item"
                            :key="item.value+'price'"
                            :class="{ isactive: params.filter.price == item.value }"
                            @click="paramsChange('price',item.value)"
                            
                        >
                            {{ item.rent }}
                        </li>
                        </ul>
                    </div>
                </div>
                <div class="btn">
                    <el-button type="text" @click="clearParams">清空筛选项</el-button>
                    <el-button style="margin-left:30px" type="text" @click="sotred">
                        {{sortMethod?'倒序':'正序'}}
                        <i class="el-icon-sort "></i>
                    </el-button>
                </div>
                <div class="main">
                    <div v-if="pageData.list&&pageData.list.length>0" class="pageList">
                        <div class="r-item" v-for="item in pageData.list" :key="item.id">
                            <div class="r-content">
                                <div class="r-title">{{item.title}}</div>
                                <div class="r-p">
                                    <span>面积: {{item.expectArea}}m²</span> 
                                    <span>户型：{{item.houseType}}  </span>
                                    <span>用途：{{item.rentUse}}</span>
                                </div>
                                <div class="r-p"><span>期望区域：</span>{{item.regionName}}</div>
                                <div class="r-p">
                                    <span>需求说明：{{item.requirements}}</span>
                                </div>
                                <div class="r-p">
                                    <span>联系人：{{item.contacts}}{{item.contactsType}}</span>
                                    <span>联系方式：{{item.telephone}}</span>
                                </div>
                            </div>
                            <div class="price">
                                <div class="">期望租金：<span class="p-value">{{item.price}}</span>元/月</div>
                            </div>
                        </div>
                        <div style="text-align:center;margin-top:16px">
                            <el-pagination background layout="prev, pager, next" :total="pageData.total" @current-change="pageChange"></el-pagination>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <el-empty description="暂无相关数据"></el-empty>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="我的求租" name="second">
                <div v-if="token">
                    <div style="text-align:right">
                        <el-button type="primary" @click="add">新增求租</el-button>
                    </div>
                    <div class="mylist">
                        <div v-if="myData.list&&myData.list.length>0" class="pageList">
                            <div class="r-item" v-for="item in myData.list" :key="item.id">
                                <div class="r-content">
                                    <div class="r-title">{{item.title}}</div>
                                    <div class="r-p">
                                        <span>面积: {{item.expectArea}}m²</span> 
                                        <span>户型：{{item.houseType}}  </span>
                                        <span>用途：{{item.rentUse}}</span>
                                    </div>
                                    <div class="r-p"><span>期望区域：</span>{{item.regionName}}</div>
                                    <div class="r-p">
                                        <span>需求说明：{{item.requirements}}</span>
                                    </div>
                                    <div class="r-p">
                                        <span>联系人：{{item.contacts}}{{item.contactsType}}</span>
                                        <span>联系方式：{{item.telephone}}</span>
                                    </div>
                                </div>
                                <div class="price">
                                    <div class="">期望租金：<span class="p-value">{{item.price}}</span>元/月</div>
                                </div>
                                <div class="t-delete">
                                    <span style="margin-right:30px">状态：<span style="color:#409EFF">{{statusText[item.status]}}</span></span>
                                    <el-button v-if="item.status == 1" size="mini" @click="toCheck(item.id)" type="warning">提交审核</el-button>
                                    <el-button size="mini" @click="deleteItem(item.id)" type="info" icon="el-icon-delete" ></el-button>
                                </div>
                            </div>
                            <div style="text-align:center;margin-top:16px">
                                <el-pagination background layout="prev, pager, next" :total="myData.total" @current-change="pageChange"></el-pagination>
                            </div>
                        </div>
                        <div class="empty" v-else>
                            <el-empty description="暂无相关数据"></el-empty>
                        </div>
                    </div>
                </div>
                <div v-else class="gologin">
                    <div>请先登录</div>
                    <el-button type="success" @click="gologin">去登录</el-button>
                </div>
            </el-tab-pane>

        </el-tabs>
        <el-dialog title="新增求租信息" :visible.sync="dialogVisible" width="840px" @close="editFormClear">
            <el-form ref="editForm" class="eform" :model="editForm" :rules="editFormRules" label-width="120px">
                <el-form-item label="标题" prop="title" class="w100">
                    <el-input v-model.trim="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="资源类型" prop="resource" class="w50">
                    <el-select v-model="editForm.resource" filterable placeholder="请选择资源类型" style="width:100%;">
                        <el-option
                        v-for="item in resourceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="期望租金(月)" prop="price" class="w50">
                    <el-input-number v-model.number="editForm.price" :controls="false" auto-complete="off" />
                </el-form-item>
                <el-form-item label="期望户型" prop="houseType" class="w50">
                    <el-select v-model="editForm.houseType" filterable placeholder="请选择期望户型" style="width:100%;">
                        <el-option
                        v-for="item in houseTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="期望面积" prop="expectArea" class="w50">
                    <el-input-number v-model="editForm.expectArea" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item  label="期望区域" prop="regionCode" class="w50">
                    <el-cascader :options="addRegionCodeOptions" v-model="editForm.regionCode" clearable placeholder="试试搜索：期望区域" filterable  :props="{ emitPath: false ,checkStrictly: true}" :show-all-levels="false" style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="租赁用途" prop="rentUse" class="w50">
                    <el-select v-model="editForm.rentUse" filterable placeholder="请选择租赁用途" style="width:100%;">
                        <el-option
                        v-for="item in rentUseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="联系人" prop="contacts" class="w50">
                    <el-input v-model="editForm.contacts" auto-complete="off" />
                </el-form-item>
                <el-form-item label="联系方式" prop="telephone" class="w50">
                    <el-input v-model="editForm.telephone" auto-complete="off" />
                </el-form-item>
                <el-form-item label="联系人类型" prop="contactsType" class="w50">
                    <el-select v-model="editForm.contactsType" filterable placeholder="请选择联系人类型" style="width:100%;">
                        <el-option
                        v-for="item in contactsTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="租赁年限" prop="rentYears" class="w50">
                    <!-- <el-date-picker
                        v-model="editForm.rentYears"
                        type="year"
                        format="yyyy"
                        value-format="yyyy"
                        :picker-options="datePickerOptions"
                    /> -->
                    <el-input-number v-model="editForm.rentYears" :min="0.5" :step="0.5" step-strictly :max="70" label="租赁年限"></el-input-number>
                </el-form-item>
                <el-form-item label="详细需求" prop="requirements" class="w100">
                    <el-input v-model="editForm.requirements" type="textarea" :rows="3" auto-complete="off" style="width:80%" />
                </el-form-item>
            </el-form>
            <div class="submitbox">
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {GetRentseeking,RentseekingAdd,RentseekingDelete,GetRentseekingForweb,rskSubmit} from '@/api/ziyuanjishi'
import regionData from "@/data/xiningregion.js";
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState({
            token:state=>state.token,
            id:state=>state.id,
        })
    },
    watch:{
        token(a){
            if(a){
                this.getMyList();
            }
        }
    },
    data(){
        return{
            dialogVisible:false,
            activeName:'first',
            statusText:{1: '草稿', 2: '提交审核', 3: '审核通过', 4: '审核不通过', 5: '驳回', 9: '作废'},
            region: [
                // { name: "全部", code: 0 },
                { name: "大通县", code: 630121 },
                { name: "湟源县", code: 630123 },
                { name: "湟中区", code: 630122 },
                { name: "城东区", code: 630102 },
                { name: "城中区", code: 630103 },
                { name: "城西区", code: 630104 },
                { name: "城北区", code: 630105 },
            ],
            resourceList: [
                {
                    value: '农房',
                    label: '农房'
                }, 
                {
                    value: '土地',
                    label: '土地'
                }
            ],
            //联系人类型
            contactsTypeList : [{
                value: '先生',
                label: '先生'
                }, {
                value: '女士',
                label: '女士'
            }],
            datePickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            //期望户型
            houseTypeList : [{
                value: '1室',
                label: '1室'
                }, {
                value: '2室',
                label: '2室'
                }, {
                value: '3室',
                label: '3室'
                }, {
                value: '4室',
                label: '4室'
                },{
                value: '其他',
                label: '其他'
                },
            ],
            //租赁用途
            rentUseList : [{
                value: '民宿',
                label: '民宿'
                }, {
                value: '农家乐',
                label: '农家乐'
                }, {
                value: '康养',
                label: '康养'
                }, {
                value: '工作室',
                label: '工作室'
                }, {
                value: '仓储',
                label: '仓储'
                }, {
                value: '其他',
                label: '其他'
            }],
            addRegionCodeOptions: regionData.region,
            params:{currentPage:1,pageSize:10,filter:{}},
            myParams:{currentPage:1,pageSize:10,filter:{}},
            rentUse:['民宿','农家乐','康养','工作室','仓储','其他'],
            rental: [
                { rent: "1千元以下", value: '0-1000' },
                { rent: "1~2千元", value: '1000-2000' },
                { rent: "2~3千元", value: '2000-3000' },
                { rent: "3千元以上", value: '3000-1000000000' },
            ],
            sortMethod:true,
            pageData:[],
            myData:{},
            editForm:{},
            editFormRules:{
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                resource: [{ required: true, message: '请输入资源类型', trigger: 'blur' }],
                price: [{ required: true, message: '请输入期望租金', trigger: 'blur' }],
                houseLayout: [{ required: true, message: '请选择期望户型', trigger: 'blur' }],
                expectArea: [{ required: true, message: '请输入期望面积', trigger: 'blur' }],
                regionCode: [{ required: true, message: '请选择期望区域', trigger: 'blur' }],
                contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                telephone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
                requirements: [{ required: true, message: '请输入详细需求', trigger: 'blur' }],
                contactsType: [{ required: true, message: '请输入类型', trigger: 'blur' }],
                rentYears: [{ required: true, message: '请选择租赁年限', trigger: 'blur' }]
            },
        }
    },
    methods:{
        tabChange(a){
            console.log(a.name,'tab');
            if(a.name === 'second'){
                if(this.token){
                    this.getMyList();
                }
            }else{
                this.getPageList();
            }
        },
        editFormClear(){
            this.$refs['editForm'].clearValidate();
        },
        add(){
            this.dialogVisible = true;
            this.editForm = {};
        },
        submit(){
            this.$refs.editForm.validate(valid=>{
                if(valid){
                    this.editForm.regionCode = +this.editForm.regionCode;
                    this.editForm.releaseTime = '2022-05-11';
                    RentseekingAdd(this.editForm).then(res=>{
                        if(res.data.success){
                            this.$message({
                                message:'新增成功',
                                type:'success'
                            });
                            this.dialogVisible = false;
                            this.getMyList();
                            
                        }
                    })
                }
            })
        },
        toCheck(id){
            const s_params={
                tableName:'t_s_rentseeking',
                status:2,
                operate:'提交审核',
                objectId:id,
                approveInfo:'',
            }
            rskSubmit(s_params).then(res=>{
                if(res.data.code){
                    this.$message({
                        message:'操作成功',
                        type:'success'
                    })
                    this.getMyList();
                }
            })
        },
        gologin(){
            this.$bus.$emit('showLogin')
        },
        getMyList(){
            this.myParams.filter.createdUserId = this.id;
            GetRentseeking(this.myParams).then(res=>{
                this.myData = res.data.data;
            })
        },
        getPageList(){
            GetRentseekingForweb(this.params).then(res=>{
                this.pageData = res.data.data;
            })
        },
        paramsChange(obj,value){
            if(value){
                this.$set(this.params.filter,obj,value)
            }else{
                delete this.params.filter[obj]
            }
            this.getPageList();
        },
        clearParams(){
            this.params.filter = {};
            this.getPageList();
        },
        pageChange(page){
            this.params.pageSize = page
            this.getPageList()
        },
        sotred(){
            this.sortMethod = !this.sortMethod;
            this.pageData.list = this.pageData.list.reverse();
        },
        deleteItem(id){
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                RentseekingDelete(id).then(res=>{
                    if(res.data.success){
                        this.$message({
                            message:"删除成功",
                            type:'success'
                        })
                        this.getMyList();
                    }
                })
            }).catch(() => {         
            });
        },
    },
    mounted(){
        this.getPageList();
    }
}
</script>

<style scoped>
.submitbox{
    text-align: center;
}
.eform .w100{
    width: 100%;
}
.eform .w50{
    width: 50%;
}
.eform {
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
}
.gologin div{
    font-size: 14px;
    color: #777;
}
.gologin{
    text-align: center;
    padding: 40px;
    line-height: 40px;
}
.empty{
    height: 500px;
}
.pionter{
    cursor: pointer;
}
.main{
    border-top: 2px solid #777;
}
.p-value{
    color: #f35c15;
    font-size: 50px;
}
.r-item{
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #aac1ee;
}
.r-item:first-child{
    border-top: 1px solid #aac1ee;
}
.t-delete{
    position: absolute;
    right: 50px;
    top: 20px;
}
.price{
    position: absolute;
    right: 50px;
    bottom: 30px;
    color:#888;
    font-size: 16px;
}
.r-p span{
    margin-right: 20px;
}
.r-p{
    line-height: 30px;
    font-size: 15px;
    color: #888;
}
.r-title{
    font-size: 22px;
    line-height: 42px;
}
.btn >>> .el-button{
    font-size: 16px;
}
.classify-item {
  padding: 0px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}
.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
  cursor: pointer;
}
.params-box {
  margin-top: 20px;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 16px;
  /* font-weight: 600; */
  /* height: 40px; */
  line-height: 40px;
}
.params-box label {
  width: 50px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 600;
}
</style>