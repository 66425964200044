<!--盘活资源-->
<template>
  <div class="resource">
    <div class="w-1400">
      <div class="charts" @click="$router.push('/List?infoType=325317333450821&type=闲置资产统计')"></div>
      <div class="r-menu row">
        <div class="r-menu-item" @click="actmenu = 1" :class="{'r-act':actmenu === 1}"><i class="el-icon-office-building"></i> 出租信息</div>
        <div class="r-menu-item" @click="actmenu = 2" :class="{'r-act':actmenu === 2}"><i class="el-icon-house"></i> 求租信息</div>
        <div class="r-menu-item" @click="actmenu = 3" :class="{'r-act':actmenu === 3}"><i class="el-icon-place"></i> 地图找房</div>
      </div>
      <el-row :gutter="30" v-if="actmenu === 1">
        <el-col :span="20">
          <div class="params-box">
            <div class="clearfix">
              <label class="fl">类型</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div class="fl" :class="{isactive:!params.filter.resource}" @click="params.filter.resource='';search();"><span class="noLimit">不限</span></div>
              <ul class="clearfix fl search-list">
                <li
                  v-for="item in resourcelist"
                  class="fl classify-item"
                  :key="item"
                  :class="{isactive:params.filter.resource==item}"
                  @click="params.filter.resource=item;search();"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="clearfix">
              <label class="fl">地区</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div
                class="fl"
                :class="{ isactive: params.filter.regionCode==6301 }"
                @click="params.filter.regionCode = 6301;
                  search();
                "
              >
                <span class="noLimit">不限</span>
              </div>
              <ul class="clearfix fl search-list">
                <li
                  v-for="item in region"
                  class="fl classify-item"
                  :key="item.code"
                  :class="{ isactive: params.filter.regionCode == item.code }"
                  @click="
                    params.filter.regionCode = item.code;
                    search();
                  "
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="clearfix">
              <label class="fl">租金</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div
                class="fl"
                :class="{ isactive: !params.filter.priceRange }"
                @click="
                  params.filter.priceRange = '';
                  search();
                "
              >
                <span class="noLimit">不限</span>
              </div>
              <ul class="clearfix fl search-list">
                <li
                  v-for="item in priceRange"
                  class="fl classify-item"
                  :key="item.value"
                  :class="{ isactive: params.filter.priceRange == item.value }"
                  @click="
                    params.filter.priceRange = item.value;
                    search();
                  "
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="clearfix">
              <label class="fl">面积</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div
                class="fl"
                :class="{ isactive: !params.filter.floorAreaRange }"
                @click="
                  params.filter.floorAreaRange = '';
                  search();
                "
              >
                <span class="noLimit">不限</span>
              </div>
              <ul class="clearfix fl search-list">
                <li
                  v-for="item in floorAreaRange"
                  class="fl classify-item"
                  :key="item.val"
                  :class="{ isactive: params.filter.floorAreaRange == item.val }"
                  @click="
                    params.filter.floorAreaRange = item.val;
                    search();">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <!-- <div class="clearfix">
                <label class="fl">年限</label>
                <i class="fl" style="margin-right:20px">:</i>
                <div class="fl"  :class="{isactive:!params.filter.buildYearRange}" @click="params.filter.buildYearRange='';search();"><span class="noLimit">不限</span></div>
                <ul class="clearfix fl search-list">
                    <li  v-for="item in buildYearRange" class="fl classify-item" 
                    :key="item.val"
                    :class="{isactive:params.filter.buildYearRange==item.val}"
                    @click="params.filter.buildYearRange=item.val;search();">{{item.name}}</li>
                </ul>
            </div> -->
            <div class="clearfix">
              <label class="fl">户型</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div
                class="fl"
                :class="{ isactive: !params.filter.houseLayout }"
                @click="
                  params.filter.houseLayout = '';
                  search();
                "
              >
                <span class="noLimit">不限</span>
              </div>
              <ul class="clearfix fl search-list">
                <li
                  v-for="item in houseLayoutRange"
                  class="fl classify-item"
                  :key="item"
                  :class="{ isactive: params.filter.houseLayout == item }"
                  @click="
                    params.filter.houseLayout = item;
                    search();
                  "
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="clearfix">
              <div class="fl" style="width:74px">
                <label class="fl">特色</label>
                <i class="fl" style="margin-right: 20px">:</i>
              </div>
              <div class="fl" style="width:calc(100% - 74px)">
                <div
                  class="fl"
                  :class="{ isactive: !params.filter.features }"
                  @click="
                    params.filter.features = '';
                    search();
                  "
                >
                  <span class="noLimit">不限</span>
                </div>
                <div
                  v-for="item in featureData"
                  class="fl classify-item"
                  :key="item"
                  :class="{ isactive: params.filter.features == item }"
                  @click="
                    params.filter.features = item;
                    search();
                  "
                >
                  {{ item }}
                </div>
              </div>
              
              <!-- <ul class="clearfix fl search-list"> -->
                
            </div>
            <div class="clearfix">
              <div class="clearfix">
                <label class="fl">条件</label>
                <i class="fl" style="margin-right: 20px">:</i>
                <div class="fl isactive" @click="clearParams"><span>清空筛选项</span></div>
              </div>
              <!-- <label class="fl"  :class="{ isactive: params.filter.orderName == 'Sort' }"
                @click="params.filter.orderName = 'Sort';getPageList();">排序</label>
              <i class="fl" style="margin-right: 20px">:</i>
              <div
                class="fl"
                :class="{ isactive: params.filter.orderName == '' }"
                @click="
                  params.filter.orderName = '';
                  getPageList();
                "
              >
                默认
              </div> -->
            </div>
          </div>
          <!-- <div v-if="pageData.list && pageData.list.length > 0"> -->
            <div class="res-main mt-20">
              <div
                class="res-item clearfix"
                v-for="item in pageData.list"
                :key="item.id+'list'"
              >
                <div class="res-pic fl">
                  <router-link :to="`/propertydetail?id=${item.id}&type=1`">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls && item.photoUrls.split(',')[0]"
                    ></el-image>
                  </router-link>
                </div>
                <div class="fr res-info">
                  <router-link :to="`/propertydetail?id=${item.id}&type=1`">
                    <h4>{{ item.title }}</h4>
                  </router-link>
                  <h5 class="ellipsis">
                    {{ item.floorArea }}m² 
                    <i class="iconfont icon-vertical_line"></i> 
                    {{ item.houseType }}
                    <i class="iconfont icon-vertical_line"></i> 
                    {{ item.houseLayout }}
                  </h5>
                  <div style="color:#999;font-size: 14px;line-height: 28px;"><i class="el-icon-location"></i>{{item.regionName}}</div>
                  <div style="color:#3081d2;font-size: 16px;line-height: 28px;"><i class="el-icon-phone"></i>{{item.telephone}}</div>
                  <div class="res-mark" v-if="item.features">
                    <span
                      v-for="(v,k) in item.features.split(',').slice(0,8)"
                      :key="v + item.id+k"
                      >{{ v }}
                    </span>
                    <span v-if="item.features.split(',').length>8">···</span>
                  </div>
                  <div style="line-height:36px;font-size:14px;color:#666;text-align: right;">{{item.createdTime}}</div>
                  <div class="res-price">
                    <span><i style="font-size:28px;font-weight: bold;">{{ item.price }}</i>元/月</span>
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->
          <!-- <div style="height: 500px; border-top: 1px solid #ddd" v-else>
            <el-empty description="暂无数据"></el-empty>
          </div> -->
          <el-pagination
            class="pagination"
            background
            layout="prev, pager, next,jumper"
            :total="pageData.total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-col>
        <el-col :span="4">
          <div class="tj">
            <h4>资源推荐</h4>
            <div class="tj-box" v-for="itemv in resourcePageData.list" :key="itemv.id">
              <router-link :to="`/propertydetail?id=${itemv.id}&type=1`">
                <div class="tj-pic">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="itemv.photoUrls.split(',')[0]"
                    ></el-image>
                </div>
                <div class="tj-title ellipsis">{{ itemv.title }}</div>
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
      <rentseeking v-if="actmenu === 2"></rentseeking>
      <houseMap v-if="actmenu === 3"></houseMap>
    </div>
  </div>
</template>
<script>
import { GetHousePage } from "@/api/home.js";
import rentseeking from '@/components/zyjs/rentseeking'
import houseMap from '@/components/zyjs/houseMap'
export default {
  data() {
    return {
      resourcelist:["农房","土地"],//资源类型
        region: [//地区
          // { name: "全部", code: 0 },
          { name: "大通县", code: 630121 },
          { name: "湟源县", code: 630123 },
          { name: "湟中区", code: 630122 },
          { name: "城东区", code: 630102 },
          { name: "城中区", code: 630103 },
          { name: "城西区", code: 630104 },
          { name: "城北区", code: 630105 },
        ],
      priceRange: [//租金
        { name: "1千元以下", value:"0-1000"},
        { name: "1~2千元", value:"1000-2000"},
        { name: "2~3千元", value:"2000-3000"},
        { name: "3千元以上", value:"3000-1000000000"},
      ],
      floorAreaRange: [//面积
        { name: "100m²以下", val:"0-100"},
        { name: "100~300m²", val:"100-300"},
        { name: "300~500m²", val:"300-500"},
        { name: "500m²以上", val:"500-100000"},
      ],
      buildYearRange:[//年限
          {name:"2年以下",val:"0-2"},
          {name:"2~5年",val:"2-5"},
          {name:"5~10年",val:"5-10"},
          {name:"10~15年",val:"10-15"},
          {name:"15~20年",val:"15-20"},
          {name:'20年以上',val:"20-1000000"}
      ],
      houseLayoutRange: ['1室', '2室', '3室', '4室', '5室', '6室', '7室', '8室', '9室', '10室', '11室', '12室', '其他'],//房屋户型
      featureData: ['山景', '高性价比', '独立院落', '近高速', '可垂钓', '拎包入住', '养宠物', '可种菜', '景区旁', '水景', '公交直达', '公路旁', '独门独栋', '其他'],
      pageData: {},
      resourcePageData:[],
      resourcepageParams:{currentPage:1,pageSize:10},
      minPrice:"",
      maxPrice:"",
      params: {
        currentPage: 1,
        filter: {
         regionCode:6301,
         maxPrice:"",
         minPrice:"",
         priceRange:"",
        },
        pageSize: 10,
      },
      actmenu:1
    };
  },
  components:{rentseeking,houseMap},
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
  },
  methods: {
    search(){
      this.params.currentPage = 1;
      this.getPageList()
    },
    getPageList() {
      GetHousePage(this.params).then((res) => {
        this.pageData = res.data.data;
      });
    },
    getPagaList() {
      GetHousePage(this.resourcepageParams).then(res=>{
          this.resourcePageData = res.data.data;
      })
    },
    //分页
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getPageList();
    },
    clearParams() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        filter: {
         classify:"",
         regionCode:6301,
         maxPrice:"",
         minPrice:"",
         rental:"",
         area:"",
         old:"",
         size:"",
         want:""
        },
      };
      this.getPageList();
    },
  },
  mounted() {
    this.getPageList();
    this.getPagaList();
  },
};
</script>
<style scoped>
.charts{
  width: 100%;
  height: 500px;
  background: url("../../assets/img/bg/table.png") no-repeat center center;
  margin: 30px 0 16px;
  border-bottom: 3px solid #e5e5e5;
  cursor: pointer;
}
.r-menu-item.r-act{
  background: #fe620d;
  color: #fff;
  border:0
}
.r-menu-item{
  padding:8px 12px;
  font-size: 18px;
  font-weight: 600;
  border:1px solid #eee;
  border-radius: 5px;
  margin-right: 30px;
  cursor: pointer;
}
.tj-title {
  line-height: 32px;
  font-size: 16px;
}
.tj-pic {
  height: 100px;
}
.tj h4 {
  font-size: 20px;
  line-height: 42px;
}
.tj {
  padding: 0 5px;
  border: 1px solid #e4e7ed;
  border-radius: 3px;
  text-align: center;
}
.res-price span:first-child {
  font-size: 20px;
  color: #fe620d;
}
.res-price {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  font-size: 16px;
  margin-top: 30px;
}
.res-mark span:nth-child(1n) {
  background: #eaf3fa;
  color: #3081d2;
}
.res-mark span:nth-child(2n) {
  background: #e6f2e6;
  color: #187e18;
}
.res-mark span:nth-child(3n) {
  background: #faeaea;
  color: #ce3233;
}
.res-mark span {
  border-radius: 19px;
  padding: 0px 20px;
  line-height: 38px;
  color: #fff;
  margin-right: 16px;
  font-size: 16px;
  display: inline-block;
  margin-top: 5px;
}
.res-mark {
  margin-top: 8px;
  /* line-height: 20px; */
}
.res-info h5 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}
.res-info h4:hover {
  color: #0e69c2;
}
.res-info h4 {
  font-size: 20px;
  line-height: 36px;
}
.res-info {
  width: calc(100% - 390px);
  position: relative;
}
.res-pic {
  width: 360px;
  height: 225px;
}
.res-item {
  padding: 20px 0;
  border-top: 1px solid #e4e7ed;
}
.classify-item {
  padding: 0px 16px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.params-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #367bff;
  cursor: pointer;
}
div.isactive{
  position: relative;
  /* padding: 0 14px; */
}
div.isactive span{
  background: #367bff;
  color: #Fff;
  display: inline-block;
  line-height: 24px;
  padding: 0 12px;
  /* line-height: 24px; */
}
div .noLimit{
  padding: 0 12px;
  line-height: 24px;
  cursor: pointer;
  display: inline-block;
}
.params-box {
  margin-top: 20px;
  /* font-family: '宋体'; */
}
.params-box div {
  font-size: 16px;
  /* font-weight: 600; */
  /* height: 40px; */
  line-height: 40px;
}
.params-box label {
  width: 50px;
  text-align: justify;
  text-align-last: justify;
  font-weight: 600;
  color: #666;
}
.resource {
  font-family: "微软雅黑";
  background: url(../../assets/img/zyjsbg1.png) no-repeat;
  background-size: auto 300px;
}
</style>